.container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    max-width: 80%;
    margin-left: auto;
}

.message {
    padding: 20px;
    background: #e8ebfa;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
    outline: transparent solid 1px;
}
