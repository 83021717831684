.container {
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
}

.disabled {
    opacity: 0.4;
}
